.architectural {
    font-family: "3M Circular TT";
    font-weight: bold;
    font-style: italic;
}

.finishes {
    font-family: "3M Circular TT";
    font-weight: normal;
}

.kt-login.kt-login--v1 .kt-login__wrapper {
    justify-content: center !important;
}