.experiencia-bk {
  background: #f5f5f5;
  display: block;
  min-height: 100vh;
}
.experiencia {
  /* font-family:Proxima Nova,-apple-system,Lato,Helvetica,Arial,sans-serif; */
  width: 100%;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  &#building .header {
    background-color: #8cbd15;
  }
  &#auto .header {
    background-color: #8e0b6e;
  }

  &#arqfinishes .header {
    background-color: #2b2b2b;
  }

  &#building .some-color {
    background-color: #8cbd15;
  }
  &#auto .some-color {
    background-color: #8e0b6e;
  }
  &#arqfinishes .some-color {
    background-color: #2b2b2b;
  }


  &#building .colored {
    color: #8cbd15;
  }
  &#building .img {
    margin-right: 5px;
  }

  &#auto .colored {
    color: #8e0b6e;
  }

  &#arqfinishes .colored {
    color: #2b2b2b;
  }

  .header {
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding-top: 15px;
  }

  .header img {
    justify-self: flex-start;
  }

  .container {
    width: 900px;
    background: #fff;
    padding: 0;
  }

  .titulo {
    background-color: #2b2b2b;
    color: #fff;
    text-align: center;
    padding: 30px 0;
    border-bottom: 2em solid #f5f5f5;
    top: -50px;
    position: relative;
  }

  .content {
    margin: 2em 5em;
    text-align: center;
    h1 {
      padding-bottom: 0.5em;
      font-weight: bold;
      font-size: 3em;
    }
    h4 {
      padding-bottom: 2em;
      font-weight: bold;
      font-size: 1.8em;
    }
    h6 {
      padding-bottom: 1em;
      font-weight: bold;
      font-style: italic;
      font-size: 1.5em;
    }
  }
  .form {
    border-top: 1px solid #f3f3f3;
    margin: 2em 5em;
    text-align: center;
    h6 {
      padding-top: 2em;
      padding-bottom: 0.5em;
      font-weight: bold;
      font-size: 1.2em;
    }
    .SubmitForm {
      margin-top: 1.5em;
      font-size: 1.5em;
    }
    .error-msg {
      text-align: left;
      color: #f00;
    }
  }
  &#building .form {
    .MuiSvgIcon-colorPrimary {
      color: #8cbd15;
    }
    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: #8cbd15;
      }
    }
  }
  &#auto .form {
    .MuiSvgIcon-colorPrimary {
      color: #8e0b6e;
    }
    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: #8e0b6e;
      }
    }
  }
  &#arqfinishes .form {
    .MuiSvgIcon-colorPrimary {
      color: #f40104;
    }
    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: #f40104;
      }
    }
  }
  &#arqfinishes {
    .titulo {
      background-color: #989898;
      color: #FFF;
    }
  }
}

.row {
  margin: 0;
}

.logo {
  margin: 0 750px 0 0;
}