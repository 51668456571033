#reset-bg{ 
    z-index: 0;
    position: relative;
    background-color: #f5f5f5;
height: 100vh;}
    
#reset-header{
z-index: 100;
    position: relative;
    height: 28vh;
    background-color: #232323;}
#reset-header-logo{
    width: 16vw;
    margin-top: 5vh;
    margin-left: 12vw;
}
#reset-content{    
    margin: 0 28vw;
    width: 44vw;
    position: absolute;
    top: 15vh ;
    
}

#reset-content-header{
    display: flex;
    background-color: #2b2b2b;
    height: 20vh;
    margin-bottom: 1.5vh;
    
}
#reset-header-title{
    width: 100%;
    text-align: center;
    color: white;
    font-size: 2.5em;
    font-weight: 600;
    padding-top: 2vh;
    align-self:center;
}
#reset-content-form{
    margin-top: 1.5vh;
    background-color: white;
    min-height: 40vh;
}
#reset-content-form-message-success{
    padding-top: 5vh;
    color:#73c968
}
#reset-content-form-message{
padding: 3% 21%;
text-align: center;
font-size: 1.1em;
line-height: 1.2;
font-weight: 500;
margin-bottom: 0;
}
.reset-content-form-input{
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    height: 2em;
    width: 50%;   
    border-color:#ebedf2;
    box-shadow: none;
    border-image: none;
    margin: 0 25%;
    color: black;
    background-color: white;
    appearance: none;
}
#reset-content-form-button-container{
    width: 100%; 
    text-align: center;  
   }
.reset-content-form-button{
    min-width: 30%!important ;
}
#reset-content-footer-message{
    padding: 3% 8%;
    text-align: center;
    font-size: 1.1em;
    line-height: 1.2;
    font-weight: 500;
    }

    @media screen and (max-width: 992px) {
        #reset-header{
            z-index: 100;
                position: relative;
                height: 16vh;
                background-color: #232323;}
        #reset-header-logo{
            width: 40vw;
            margin-top: 2vh;
            
        }
        #reset-content{    
            margin: 0 10vw;
            width: 80vw;
            top: 10vh ;
        }
        #reset-content-header{           
            height:12vh;
        }
        
        #reset-header-title{
             font-size: 1.5em;
            padding-top: 2vh;
           
        }
        #reset-content-form-message{
            padding: 3% 5%;
            text-align: center;
            font-size: 1.1em;
            line-height: 1.2;
            font-weight: 500;
            }
            
            #reset-content-footer-message{
                margin-top: 5vh;
                padding: 3% 8%;
                text-align: center;
                font-size: 1.1em;
                line-height: 1.2;
                font-weight: 500;
                }
      }
      
      /* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
      @media screen and (max-width: 600px) {
        #reset-header{
            z-index: 100;
                position: relative;
                height: 16vh;
                background-color: #232323;}
        #reset-header-logo{
            width: 40vw;
            margin-top: 2vh;
            
        }
        #reset-content{    
            margin: 0 10vw;
            width: 80vw;
            top: 10vh ;
        }
        #reset-content-header{           
            height:12vh;
        }
        
        #reset-header-title{
             font-size: 1.5em;
            padding-top: 2vh;
           
        }
        #reset-content-form-message{
            padding: 3% 5%;
            text-align: center;
            font-size: 1.1em;
            line-height: 1.2;
            font-weight: 500;
            }
            
            #reset-content-footer-message{
                margin-top: 5vh;
                padding: 3% 8%;
                text-align: center;
                font-size: 1.1em;
                line-height: 1.2;
                font-weight: 500;
                }
      }
