
.full-page{
    height: 100vh;
}

.end-user-thanks{
    padding: 20vh 5vw 1vh 0vw;    
    color: white;
    font-size: 4rem;
    font-weight: bold;
    line-height:4.25rem;
}

.end-user-complete{
    padding: 2vh 12.5vw 5vh 0vw;    
    color: white;
    font-size: 2rem;
    
}
.end-user-form{
    padding: 3vh 2.5vw 0vh 2vw;
}
.end-user-form-bg{
    background-color: white;
    min-height: 250px;
    max-width: 30vw;
    border-bottom: red 4px solid;
    padding-bottom: 2%;
    margin-bottom: 2%;    
}
#survey-button-container{
    margin:5vh 0 ;
    padding:0 10% ;
}
.survey-form-button{
    height: 25px;
    padding: 5px 0;
    
    
    min-width: 45%!important
}

.yes-survey, .yes-survey:hover, .yes-survey:visited{
    border-color: #3cba19;
    background-color: #3cba19;
    margin-right:10%;
}
.enduser-content-form-input:hover{
    border-color: rgba(0, 0, 0, 1);
}
.enduser-content-form-input{ 
    border-color: rgba(0, 0, 0, 0.23);
    border-radius: 0.25rem;
    border-style:solid;
    border-width: 1px;   
    width: 90%;
    padding-right: 2.5vw!important;
    font-weight: normal!important;    
}

.enduser-content-form-input:focus{    
    border-color: red;
    outline: red;
}
.enduser-label{
    
    margin-bottom: 0;
    font-size: small;
}
.enduser-form-title{
    padding-top: 5%;    
    font-size: 1.5rem;
    padding-bottom: 0;
    margin-bottom: 0;
}
.sep{
    width: 80%;
    margin-left: 2.5vw;
    padding-bottom: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 0.23);
    margin-bottom: 5%;
}
.enduser-select{
    margin-top:2%!important;
}
.enduser-select>:first-child>:first-child{
    padding-top: 5px!important;
    padding-bottom: 5px!important;   

}
.reduced-margin-bot{
    margin-bottom: 1%!important;
    padding-left: 2.5vw;
}
.select-contaier{
    padding-bottom: 10px!important;
}
#enduser-content-form-button-container{
   margin-left: 2.5vw;
   margin-right: 2.5vw;
}
.enduser-content-form-button{
    padding:  1vh 0;
    width:100%;
}
.enduser-check{
    margin-left: 2.5vw;
}
.check-text{
    font-size: 0.75rem!important;
    margin-top: 0!important;
    margin-right: 2.5vw;
    text-align: left;
    margin-bottom: 0;
}

.end-user-thanks-mobile{
    padding: 5vh 5vw 0vh 0vw;    
    color: white;
    font-size: 1.75rem;
    font-weight: bold;
    line-height:2.25rem;
}
.end-user-complete-mobile{
    padding: 2vh 5vw 1vh 0vw;    
    color: white;
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 0;
}
.step-2-text{
    margin-left: 10%;
    margin-right: 10%;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.43)
    
}
.step-final{
    font-size: 1.5rem;
 font-weight: bold;
 margin-left: 10%;
    margin-right: 10%;
    font-size: 1.25rem;
    color: rgba(0, 0, 0, 0.43)
}
.step-2-title{
 margin-top: 30%;   
 padding-top: 5%;
 font-size: 1.5rem;
 font-weight: bold;
 margin-bottom: 0;
}
.dark{
    color: rgba(0, 0, 0, 0.43)
}
.darker{
    color: rgba(0, 0, 0, 0.63);
    font-weight: bold;
}
.thanks-send-button{
    margin-left: 10%;

}
#download-button{
    margin-left: 2.5vw;
    padding-left:2.5%;
    height: 25px;
    padding-top: 2.5px;
    margin-bottom: 2.5vh;
    width: 80%;
}
.red-error{
    color:red;
}
.red-border{
    border-color: red;
}
.error-margin{
    margin-left: 0!important;
    margin-bottom: 1vh!important;
    
}
.error-text{
    font-size: 0.85rem!important;
}
@media screen and (min-width:1480px){
    .end-user-form{
        padding: 12.5vh 5vw 0vh 5vw;
        width: 100%;  
    }
}
@media screen and (max-width:992px){
    .end-user-form{
        padding: 2vh 5vw 0vh 5vw;
        width: 100%;  
    }
    .end-user-form-bg{
        background-color: white;
        min-height: 250px;
        max-width: 100%!important;    
    }
    #enduser-content-form-button-container{
        margin: 0 10%;
    }
    .check-text{
        margin: 0;
    }
    .enduser-content-form-input{              
        margin-right: 1.5vw!important;
        width: 95%;
        
    }
    .error-text{
        font-size: 0.75rem!important;
    }
    #download-button{
        margin-left: 7.5vw;
    }
    .step-2-text{
        font-size: 1.25rem;
    }
    .sep{
        width: 80%;
        margin-left: 7.5vw;
       
    }
   
    
    
}

