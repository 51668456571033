.film-previewer{
    display: block;
    padding: 2vh 2.5vw;
    
}
.img-previewer{
    width: 100%;
    height: 165px;
}
.img-previewer-summary{
    width: 200%;
    height: 45px;
    margin-bottom: 10px;
}
.border-bottom_sep{
    border-bottom: 1px gray;
    margin-bottom: 5px;
}