.red{
    height: 5px;
    background-color:rgb(158,37,108) ;
    background: linear-gradient(90deg, rgb(158,37,108) 0%, rgb(206,19,55) 50%, rgb(255,00,00) 100%);
    
}
.bg-white{
    background-color: white!important;
}

.blue{
    height: 5px;
    background-color:rgb(4,92,153) ;
    background: linear-gradient(90deg, rgb(4,92,153) 0%, rgb(00,179,226) 100%);

}

.black{
    height: 5px;
    background-color:rgb(0,0,0) ;
    background: linear-gradient(90deg, rgb(0,0,0) 0%,  rgb(194,194,194) 100%);

}

.green{
    height: 5px;
    background-color:rgb(28,170,79) ;
    background: linear-gradient(90deg, rgb(28,170,79) 0%,  rgb(174,204,57) 100%);

}
.orange{
    height: 5px;
    background-color:rgb(247,60,47) ;
    background: linear-gradient(90deg, rgb(247,60,47) 0%,  rgb(245,127,32) 100%);

}
.yellow{
    height: 5px;
    background-color:rgb(248,172,32) ;
    background: linear-gradient(90deg, rgb(248,172,32) 0%,  rgb(246,232,38) 100%);

}
.d-hidden{
    visibility: hidden;
  opacity: 0;
  transition: all .5s linear;
}
.d-shown{
    visibility: visible;
  opacity: 1;
  transition: all .5s linear;

}
.underlined_not{
    text-decoration: none;
    transition: all 1s linear;
}
.underlined{
    text-decoration: underline;
    transition: all 1s linear;
}
.checklist-title{
    font-size: 2rem;

}
.checklist-text{
    font-size: 1.75rem;
}
